<template>
    <div><h1>FORM SUBMITTED!</h1></div>
 </template>
 
 <script>
 export default {
     name: 'app',
     data() {
         return {
             mouseX: 0,
             mouseY: 0,
             centerX: window.innerWidth / 2,
             centerY: window.innerHeight / 2,
             text: '',
             textToCopy: '',
         };
     },
     mounted() {
 
     },
     methods: {
     },
     beforeUnmount() {
     
     },
 }
 </script>
 
 <style lang="scss">
 
 @media (max-width: 900px) {
 
 }
 
 /* Add styles for your video player here */
 </style>
 